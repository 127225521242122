import { Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as OM from '@/Model';
import { Watch } from 'vue-property-decorator';

export default abstract class BaseServerFilterTable extends Vue {

    abstract init;
    abstract tableRoot;

    requestFilter: OM.PagedRequestVM = new OM.PagedRequestVM();
    totalItems: number = 0;
    
    timeoutTime = 0;
    timeoutHandler;

    created(){
        this.requestFilter.paging = {
            currentPage : 1,
            itemsPerPage : 15,
            skip : 0,
            take : 15,
        };
        
        this.requestFilter.sortBy = null;
        this.requestFilter.filter = {};
    }

    @Watch("requestFilter", { deep: true })
    requestChanged(){
        clearTimeout(this.timeoutHandler);

        this.timeoutHandler = setTimeout(() => {
            if(this.timeoutTime == 0)
                this.timeoutTime = 500;
                
            this.init();
        }, this.timeoutTime);
    }

    changeSort(property: string){
        let headSpans = this.tableRoot.querySelectorAll('.head_list .column span');
        headSpans.forEach(element => {
            element.classList.remove("asc");
            element.classList.remove("desc");
        });

        let target = this.tableRoot.querySelectorAll('.head_list .column[param="' + property + '"] span')[0];

        if(!property)
            return;

        if(this.requestFilter.sortBy == null)
            this.requestFilter.sortBy = new OM.SortByParameter();

        if(this.requestFilter.sortBy.propertyName != property){
            this.requestFilter.sortBy.propertyName = property;
            this.requestFilter.sortBy.ascending = true;
            target.classList.add("asc");
            return;
        } 

        if(this.requestFilter.sortBy.ascending){
            this.requestFilter.sortBy.ascending = false;
            target.classList.add("desc");
            return;
        } 

        this.requestFilter.sortBy = null;
    }

    getClass(property: string){
        if(this.requestFilter.sortBy == null || this.requestFilter.sortBy.propertyName != property)
            return "";

        if(this.requestFilter.sortBy.ascending)
            return "asc";

        return "desc";
    }

}